import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-mag-svg',
    imports: [CommonModule],
    templateUrl: './mag-svg.svg',
    styleUrl: './mag-svg.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MagSvgComponent {

}
