import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-run-demo-svg',
    imports: [CommonModule],
    templateUrl: './run-demo-svg.svg',
    styleUrl: './run-demo-svg.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RunDemoSvgComponent {

}
