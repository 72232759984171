import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-abs-logo-svg',
    imports: [CommonModule],
    templateUrl: './abs-logo-svg.svg',
    styleUrl: './abs-logo-svg.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbsLogoSvgComponent {
  @Input('fillColor') fillColor: string = '#666666';
}
