import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-brand-wapro-anywhere-svg',
    imports: [CommonModule],
    templateUrl: './brand-wapro-anywhere-svg.svg',
    styleUrl: './brand-wapro-anywhere-svg.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandWaproAnywhereSvgComponent {

}
